import 'assets/styles/app.scss';
import Lenis from 'lenis';
import AOS from 'aos';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const loadedComponents = [];

window.addEventListener('load', () => {
	AOS.init({
		// offset: 200,
		duration: 600,
		easing: 'ease',
		once: true,
		anchorPlacement: 'top-bottom',
	});
});


document.addEventListener('DOMContentLoaded', async () => {
	window.disableLenis = false;
	const lenis = new Lenis();
	function raf(time) {
		if (!window.disableLenis) {
			lenis.raf(time);
		}
		requestAnimationFrame(raf);
	}

	requestAnimationFrame(raf);

	/**
	 * Bootstrap the components
	 */
	for (const dataComponent of document.querySelectorAll('[data-component]')) {
		let componentName = dataComponent.dataset.component;

		if (loadedComponents.indexOf(componentName) === - 1) {
			loadedComponents.push(componentName);

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
				);

				component.default();
			} catch (error) {
				console.error('Loading error: ', error);
			}
		}
	}

	// Get text with class 'animate-text' ready to animate
	const textToAnimate = $('.animate-text');

	textToAnimate.each(function () {
		$(this).splitLines({
			tag: '<div class="mask"><div class="line">',
			keepHtml: true
		});
	})

	// Trigger to animate headers
	if ($('.animate-text').length) {
		const animateText = document.querySelectorAll(".animate-text");
		animateText.forEach((section) => {
			gsap.to(section, {
				scrollTrigger: {
					trigger: section,
					markers: false,
					toggleClass: 'animate',
					start: "bottom 90%",
					once: true
				},
				opacity: 1,
			});
		});
	}
});
